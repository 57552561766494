<template>
  <ul class="nav nav-md flex-column fs-14">
    <li v-for="tree in treeList" :key="tree.name" class="nav-item treeMenu">
      <b-form-radio
        :class="['ms-0 mt-0', tree.showBg && 'filter']"
        name="some-radios"
        v-model="selectedId"
        :value="tree.id"
        @click.stop="handlerChange(tree.id)"
      >
        <a
          :href="`#tree${tree.id}`"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          :aria-controls="tree.id"
          :data-key="tree.name"
          :style="{
            marginLeft:
              tree.num == 1
                ? tree.num + 'rem'
                : tree.num * (0.5 + tree.num / 2) + 'rem',
            fontWeight: '400',
            fontSize: '14px',
            display: 'flex',
            position: 'relative',
            width: 300 - (tree.num - 1) * 20 + 'px',
            height: '100%',
          }"
          class="text-dark align-items-center"
          @click.stop="handlerToggleIcon"
        >
          <div>
            <i
              v-if="tree.childs && tree.childs.length != 0"
              class="ri-indeterminate-circle-line text-info me-1"
            ></i>
          </div>

          <div :class="['d-flex flex-1 tree-cont', !tree.childs && 'ms-3']">
            <div
              :style="{
                width: 300 - (tree.num - 1) * 30 + 'px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }"
            >
              {{ tree.username }}
            </div>
          </div>
        </a>
      </b-form-radio>
      <div
        :class="['collapse', 'show']"
        :id="`tree${tree.id}`"
        v-if="tree.childs"
      >
        <LeftMenu
          :treeList="tree.childs"
          :selected="selected"
          @getUserDetail="handlerChange"
        ></LeftMenu>
      </div>
    </li>
  </ul>
</template>

<script>
import LeftMenu from "./LeftMenu";
export default {
  data() {
    return {
      selectedId: "",
    };
  },
  props: {
    treeList: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: "",
    },
  },
  emits: ["getUserDetail"],
  components: {
    LeftMenu,
  },
  computed: {},
  watch: {
    selected: {
      handler(val) {
        this.selectedId = val;
      },
      immediate: true,
    },
  },
  methods: {
    handlerToggleIcon(event) {
      /* ri-add-circle-line  */
      let classList = event.target.offsetParent.firstChild.firstChild.classList;
      if (classList) {
        if ([...classList].includes("ri-indeterminate-circle-line")) {
          classList.remove("ri-indeterminate-circle-line");
          classList.add("ri-add-circle-line");
        } else {
          classList.add("ri-indeterminate-circle-line");
          classList.remove("ri-add-circle-line");
        }
      }
    },
    handlerChange(id) {
      this.selectedId = id;
      this.$emit("getUserDetail", this.selectedId);
    },
  },
};
</script>
<style scoped lang='scss'>
ul {
  padding: 0;
}
.treeMenu {
  .form-check {
    border-bottom: 1px #e9ebec solid;
    margin: 0;
    display: flex;
    align-items: center;

    .tree-cont {
      line-height: 40px;
    }
    &.hilight {
      background: rgba(102, 145, 231, 0.15);
    }
    &.filter {
      background: rgba(41, 156, 219, 0.3);
    }
  }
  .rotate {
    transform: rotate(90deg);
  }
}
</style>
