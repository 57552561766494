<template>
  <div class="modal fade show" style="display: block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-light p-3">
          <h5 class="modal-title">{{ $t("user.add") }}</h5>
          <button
            type="button"
            class="btn-close"
            @click="handleClose()"
          ></button>
        </div>
        <form action="#" id="addform">
          <div class="modal-body">
            <div class="row gy-3">
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.name") }}</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.name"
                  :class="{
                    'is-invalid': submitted && v$.form.name.$error,
                  }"
                  required
                />
                <div
                  v-for="(item, index) in v$.form.name.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.user_name") }}</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.username"
                  :class="{
                    'is-invalid': submitted && v$.form.username.$error,
                  }"
                  required
                />
                <div
                  v-for="(item, index) in v$.form.username.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.password") }}</span>
                </label>
                <div class="position-relative auth-pass-inputgroup">
                  <input
                    :type="passwordType"
                    autocomplete="new-passWord"
                    class="form-control"
                    style="padding-right: 3rem"
                    v-model="form.password"
                    :class="{
                      'is-invalid': submitted && v$.form.password.$error,
                    }"
                    required
                  />
                  <div
                    v-for="(item, index) in v$.form.password.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                  <button
                    class="
                      btn btn-link
                      position-absolute
                      top-0
                      text-decoration-none text-muted
                    "
                    style="right: 3%"
                    type="button"
                    id="password-addon"
                  >
                    <i
                      class="ri-eye-fill align-middle"
                      @click="viewPasswordClick()"
                    ></i>
                  </button>
                </div>
              </div>
              <div>
                <label for="weight-field" class="form-label">
                  <span class="text-danger">*</span>
                  <span>{{ $t("attribute.role") }}</span>
                </label>
                <Multiselect
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && v$.form.role.$error,
                  }"
                  v-model="form.role"
                  :options="roles"
                  required
                />
                <div
                  v-for="(item, index) in v$.form.role.$errors"
                  :key="index"
                  class="invalid-feedback"
                >
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </div>
              <div>
                <label class="form-label">{{
                  $t("attribute.organization")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.department"
                />
              </div>
              <div>
                <label for="weight-field" class="form-label">{{
                  $t("attribute.description")
                }}</label>
                <textarea
                  id="textarea"
                  class="form-control"
                  v-model="form.description"
                  rows="3"
                  max-rows="6"
                ></textarea>
              </div>
              <div>
                <label class="form-label">{{ $t("attribute.phone") }}</label>
                <input type="text" class="form-control" v-model="form.phone" />
              </div>
              <div>
                <label class="form-label">{{ $t("attribute.email") }}</label>
                <input type="text" class="form-control" v-model="form.mail" />
              </div>
              <div>
                <span class="text-danger">*</span>
                <label for="weight-field" class="form-label">{{
                  $t("attribute.status")
                }}</label>
                <b-form-checkbox
                  v-model="form.disabled"
                  switch
                ></b-form-checkbox>
              </div>

              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <template v-for="(v, k) in grantAll" :key="k">
                  <div class="w-50" v-if="v.show">
                    <b-form-checkbox v-model="v.value" @change="e => grantChange(k, e)">{{
                        v.title
                      }}</b-form-checkbox>
                  </div>
                </template>
              </div>

              <div>
                <label for="weight-field" class="form-label">{{
                    $t("attribute.upload_logo")
                  }}</label>
                <b-form-checkbox
                    v-model="form.logo"
                    switch
                ></b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                class="btn btn-light"
                @click="handleClose()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.cancel") }}
              </button>
              <button
                type="button"
                class="btn btn-success"
                id="add-btn"
                @click="handleSubmit()"
                :disabled="formValidataAlert"
              >
                {{ $t("base.done") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
    :showCloseBtn="true"
    v-model:formValidataAlert="formValidataAlert"
  ></message-alert>
</template>
<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import _ from "loadsh";
import i18n from "@/i18n";
import { isAdmin, getLoginData } from "@/utils/permission.js";
import { getRoleSelect, createUser } from "@/service/index";

export default {
  emits: ["update:isShowForm", "update:btnType", "initRoleList"],
  props: {
    isShowForm: Boolean,
    btnType: String,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    form: {
      name: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_name"),
          required
        ),
      },
      username: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_user_name"),
          required
        ),
      },
      password: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_password"),
          required
        ),
      },
      role: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_role"),
          required
        ),
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
        username: "",
        password: "",
        role: "",
        department: "",
        phone: "",
        mail: "",
        description: "",
        disabled: true,
        logo:true,
      },
      submitted: false,
      formValidataAlert: false,
      alertInfo: {
        variant: "success",
        content: "",
        icon: "ri-error-warning-line",
      },
      roles: [],
      passwordType: "password",
      grantAll: {
        grant_all_garment: {
          title: this.$t("base.grant_all_garment"),
          show: false,
          value: false,
        },
        grant_all_garment_view: {
          title: this.$t("base.grant_all_garment_view"),
          show: false,
          value: false,
        },
        grant_all_fabric: {
          title: this.$t("base.grant_all_fabric"),
          show: false,
          value: false,
        },
        grant_all_fabric_view: {
          title: this.$t("base.grant_all_fabric_view"),
          show: false,
          value: false,
        },
        grant_all_yarn: {
          title: this.$t("base.grant_all_yarn"),
          show: false,
          value: false,
        },
        grant_all_yarn_view: {
          title: this.$t("base.grant_all_yarn_view"),
          show: false,
          value: false,
        },
        grant_all_fibre: {
          title: this.$t("base.grant_all_fibre"),
          show: false,
          value: false,
        },
        grant_all_fibre_view: {
          title: this.$t("base.grant_all_fibre_view"),
          show: false,
          value: false,
        },
        grant_all_textile_bedware: {
          title: this.$t("base.grant_all_textile_bedware"),
          show: false,
          value: false,
        },
        grant_all_textile_bedware_view: {
          title: this.$t("base.grant_all_textile_bedware_view"),
          show: false,
          value: false,
        },
        grant_all_textile_home_decor: {
          title: this.$t("base.grant_all_textile_home_decor"),
          show: false,
          value: false,
        },
        grant_all_textile_home_decor_view: {
          title: this.$t("base.grant_all_textile_home_decor_view"),
          show: false,
          value: false,
        },
        grant_all_textile_industrial: {
          title: this.$t("base.grant_all_textile_industrial"),
          show: false,
          value: false,
        },
        grant_all_textile_industrial_view: {
          title: this.$t("base.grant_all_textile_industrial_view"),
          show: false,
          value: false,
        },
        grant_all_textile_kitchen: {
          title: this.$t("base.grant_all_textile_kitchen"),
          show: false,
          value: false,
        },
        grant_all_textile_kitchen_view: {
          title: this.$t("base.grant_all_textile_kitchen_view"),
          show: false,
          value: false,
        },
      }
    };
  },
  created() {
    this.requestRoles();
    this.initGrantAllData();
  },
  computed: {},
  methods: {
    grantChange(k,e) {
      // 控制编辑和仅查看单选
      // console.log(k,e)
      if (!e) {
        return
      }
      if (k.includes("view")) {
        let currentKey = k.replace("_view", "");
        this.grantAll.forEach((value, key) => {
          if (key === currentKey) {
            value.value = false;
          }
        });
      } else {
        let currentKey = k + "_view";
        this.grantAll.forEach((value, key) => {
          if (key === currentKey) {
            value.value = false;
          }
        });
      }
    },
    initGrantAllData() {
      _.forEach(this.grantAll, function (value, key) {
        if (isAdmin()) {
          value.show = true;
        } else {
          value.show = getLoginData()[key];
        }
      });
    },
    viewPasswordClick() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    handleClose() {
      this.$emit("update:isShowForm", false);
      this.$emit("update:btnType", "view");
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        let params = _.cloneDeep(this.form);
        params["confirm_password"] = this.form.password;
        params["disabled"] = !this.form.disabled;
        // params["logo"] = !this.form.logo;
        if (this.form.role) {
          params["role"] = [this.form.role];
        } else {
          params["role"] = [];
        }

        _.forEach(this.grantAll, function (value, key) {
          if (value.show) {
            params[key] = value.value;
          }
        });

        createUser(params)
          .then((res) => {
            if (res) {
              if (res.code == 0) {
                let info = {
                  variant: "success",
                  content: this.$t("base.add_success"),
                  icon: "ri-error-warning-line",
                };
                this.openAlert(info, "close");
                this.$emit("initRoleList");
              } else {
                this.errorAlert(res.message);
              }
            }
          })
          .catch((err) => {
            this.errorAlert(err.message);
          });
      }
    },
    /* 消息提示框 */
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
          this.handleClose();
        }, 2000);
      }
    },
    errorAlert(message) {
      let info = {
        variant: "danger",
        content: message,
        icon: "ri-error-warning-line",
      };
      this.alertInfo = info;
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
        }, 2000);
      }
    },
    requestRoles() {
      getRoleSelect()
        .then((res) => {
          if (res && res.payload) {
            let roleData = res.payload;
            roleData = this.filterRoles(roleData);
            this.roles = _.map(roleData, "name");
            if (!this.roles.length) {
              let info = {
                variant: "danger",
                content: this.$t("role.emptyRole"),
                icon: "ri-error-warning-line",
              };
              this.openAlert(info);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 筛选角色列表 */
    filterRoles(roles) {
      let newData = [];
      roles.forEach((role) => {
        if (role.childs && role.childs.length) {
          newData.push(role);
          // 存在多层嵌套
          let reData = this.filterRoles(role.childs);
          newData.push(...reData);
        } else {
          newData.push(role);
        }
      });
      return newData;
    },
  },
};
</script>
