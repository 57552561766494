<template>
  <div class="card detail-body flex-1 mb-0">
    <Detail
      :detailList="detailList"
      :userList="userList"
      @updateUserList="updateUserList"
    ></Detail>
  </div>
</template>

<script>
import Detail from "./DetailInfo.vue";
import _ from "lodash";
export default {
  data() {
    return {
      detailList: {},
      userList: [],
    };
  },
  props: {
    detailInfo: {
      type: Object,
      default: () => {},
    },
    treeList: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["initUserList"],
  watch: {
    detailInfo: {
      handler(val) {
        this.detailList = _.cloneDeep(val);
      },
      deep: true,
    },
    treeList: {
      handler(val) {
        this.userList = _.cloneDeep(val);
      },
      deep: true,
    },
  },
  methods: {
    updateUserList(id) {
      this.$emit("initUserList", id);
    },
  },
  components: {
    Detail,
  },
};
</script>

<style scoped lang='scss'>
.detail-head {
  padding: 6px;
  font-size: 16px;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}
</style>
