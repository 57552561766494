<template>
  <div class="detail-content">
    <div class="modal-header bg-light py-2 px-3">
      <h5 class="modal-title">{{ formTitle }}</h5>
      <ul class="list-inline hstack gap-2 mb-0">
        <li
          class="list-inline-item cursor"
          v-for="(btn, index) in btnList"
          :key="index"
        >
          <div
            class="text-primary d-inline-block"
            v-if="btn.show"
            @click="handlerToggleType(btn.type)"
          >
            <i
              :class="[
                'fs-16',
                btn.icon,
                btnType == btn.type && 'text-success',
              ]"
            ></i>
          </div>
        </li>
      </ul>
    </div>
    <form action="#" id="addform" v-if="userList.length">
      <div class="modal-body">
        <div class="row gy-3">
          <!-- 名称 -->
          <div>
            <label for="weight-field" class="form-label">
              <span class="text-danger">*</span>
              <span>{{ $t("attribute.name") }}</span>
            </label>
            <input
              type="text"
              class="form-control"
              v-model="form.name"
              :class="{
                'is-invalid': submitted && v$.form.name.$error,
              }"
              required
              :disabled="disabledInput"
            />
            <div
              v-for="(item, index) in v$.form.name.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
          <!-- 用户名称 -->
          <div>
            <label for="weight-field" class="form-label">
              <span class="text-danger">*</span>
              <span>{{ $t("attribute.user_name") }}</span>
            </label>
            <input
              type="text"
              class="form-control"
              v-model="form.username"
              :class="{
                'is-invalid': submitted && v$.form.username.$error,
              }"
              required
              :disabled="disabledInput"
            />
            <div
              v-for="(item, index) in v$.form.username.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
          <!-- 密码 -->
          <div>
            <label for="weight-field" class="form-label">
              <span class="text-danger">*</span>
              <span>{{ $t("attribute.password") }}</span>
            </label>
            <div class="position-relative auth-pass-inputgroup">
              <input
                :type="passwordType"
                autocomplete="new-passWord"
                class="form-control"
                style="padding-right: 3rem"
                v-model="form.password"
                :class="{
                  'is-invalid': submitted && v$.form.password.$error,
                }"
                required
                :disabled="disabledInput"
                @input="passwordInput"
              />
              <div
                v-for="(item, index) in v$.form.password.$errors"
                :key="index"
                class="invalid-feedback"
              >
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
              <button
                class="
                  btn btn-link
                  position-absolute
                  top-0
                  text-decoration-none text-muted
                "
                style="right: 3%"
                type="button"
                id="password-addon"
                v-show="!disabledInput"
              >
                <i
                  class="ri-eye-fill align-middle"
                  @click="viewPasswordClick()"
                ></i>
              </button>
            </div>
          </div>
          <!-- 角色 -->
          <div>
            <label for="weight-field" class="form-label">
              <span class="text-danger">*</span>
              <span>{{ $t("attribute.role") }}</span>
            </label>
            <Multiselect
              class="form-control"
              :class="{
                'is-invalid': submitted && v$.form.role.$error,
              }"
              v-model="form.role"
              :options="roles"
              :disabled="disabledInput"
              required
            />
            <div
              v-for="(item, index) in v$.form.role.$errors"
              :key="index"
              class="invalid-feedback"
            >
              <span v-if="item.$message">{{ item.$message }}</span>
            </div>
          </div>
          <!-- 组织 -->
          <div>
            <label class="form-label">{{ $t("attribute.organization") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="form.department"
              :disabled="disabledInput"
            />
          </div>
          <!-- 描述 -->
          <div>
            <label for="weight-field" class="form-label">{{
              $t("attribute.description")
            }}</label>
            <textarea
              id="textarea"
              class="form-control"
              v-model="form.description"
              rows="3"
              max-rows="6"
              :disabled="disabledInput"
            ></textarea>
          </div>
          <!-- 手机 -->
          <div>
            <label class="form-label">{{ $t("attribute.phone") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="form.phone"
              :disabled="disabledInput"
            />
          </div>
          <!-- 邮箱 -->
          <div>
            <label class="form-label">{{ $t("attribute.email") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="form.mail"
              :disabled="disabledInput"
            />
          </div>
          <!-- 邮箱 -->
          <div>
            <label class="form-label">{{ $t("attribute.last_time") }}:</label>
            <span class="ms-3">{{ dateFormat1(form.update_time) || "-" }}</span>
          </div>
          <!-- 状态 -->
          <div>
            <label for="weight-field" class="form-label">{{
              $t("attribute.status")
            }}</label>
            <b-form-checkbox
              v-model="form.disabled"
              switch
              :disabled="disabledInput"
            ></b-form-checkbox>
          </div>
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <template v-for="(v, k) in grantAll" :key="k">
              <div class="w-50" v-if="v.show">
                <b-form-checkbox v-model="v.value" :disabled="disabledInput">{{
                    v.title
                  }}</b-form-checkbox>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="modal-footer" v-if="btnType == 'edit'">
        <div class="hstack gap-2 justify-content-end">
          <button
            type="button"
            class="btn btn-light"
            @click="handleClose()"
            :disabled="formValidataAlert"
          >
            {{ $t("base.cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            id="add-btn"
            @click="handleSubmit()"
            :disabled="formValidataAlert"
          >
            {{ $t("base.done") }}
          </button>
        </div>
      </div>
    </form>
    <Empty flag="1" v-else></Empty>
  </div>
  <Add
    v-if="isShowAdd"
    v-model:isShowForm="isShowAdd"
    v-model:btnType="btnType"
    @initRoleList="refreshList"
  ></Add>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
    :showCloseBtn="true"
    v-model:formValidataAlert="formValidataAlert"
  ></message-alert>
</template>
		
<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import _ from "loadsh";
import Swal from "sweetalert2";
import i18n from "@/i18n";
import { dateFormat1 } from "@/utils/common.js";
import { isAdmin, getLoginData } from "@/utils/permission.js";
import { getRoleSelect, editUser, deleteUser } from "@/service/index";
import Add from "./Add";
import Empty from "@/components/common/Empty";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    form: {
      name: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_name"),
          required
        ),
      },
      username: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_user_name"),
          required
        ),
      },
      password: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_password"),
          required
        ),
      },
      role: {
        required: helpers.withMessage(
          i18n.global.t("user.hint_role"),
          required
        ),
      },
    },
  },
  data() {
    return {
      submitted: false,
      btnType: "view",
      passwordType: "password",
      formValidataAlert: false,
      hasEdit: false,
      isShowAdd: false,
      roles: [],
      alertInfo: {
        variant: "success",
        content: "",
        icon: "ri-error-warning-line",
      },
      btnList: [
        {
          icon: "ri-add-circle-fill",
          type: "add",
          show: true,
        },
        {
          icon: "ri-eye-fill",
          type: "view",
          show: false,
        },
        {
          icon: "ri-pencil-fill",
          type: "edit",
          show: false,
        },
        {
          icon: "ri-delete-bin-5-fill",
          type: "delete",
          show: false,
        },
      ],
      grantAll: {
        grant_all_garment: {
          title: this.$t("base.grant_all_garment"),
          show: false,
          value: false,
        },
        grant_all_garment_view: {
          title: this.$t("base.grant_all_garment_view"),
          show: false,
          value: false,
        },
        grant_all_fabric: {
          title: this.$t("base.grant_all_fabric"),
          show: false,
          value: false,
        },
        grant_all_fabric_view: {
          title: this.$t("base.grant_all_fabric_view"),
          show: false,
          value: false,
        },
        grant_all_yarn: {
          title: this.$t("base.grant_all_yarn"),
          show: false,
          value: false,
        },
        grant_all_yarn_view: {
          title: this.$t("base.grant_all_yarn_view"),
          show: false,
          value: false,
        },
        grant_all_fibre: {
          title: this.$t("base.grant_all_fibre"),
          show: false,
          value: false,
        },
        grant_all_fibre_view: {
          title: this.$t("base.grant_all_fibre_view"),
          show: false,
          value: false,
        },
        grant_all_textile_bedware: {
          title: this.$t("base.grant_all_textile_bedware"),
          show: false,
          value: false,
        },
        grant_all_textile_bedware_view: {
          title: this.$t("base.grant_all_textile_bedware_view"),
          show: false,
          value: false,
        },
        grant_all_textile_home_decor: {
          title: this.$t("base.grant_all_textile_home_decor"),
          show: false,
          value: false,
        },
        grant_all_textile_home_decor_view: {
          title: this.$t("base.grant_all_textile_home_decor_view"),
          show: false,
          value: false,
        },
        grant_all_textile_industrial: {
          title: this.$t("base.grant_all_textile_industrial"),
          show: false,
          value: false,
        },
        grant_all_textile_industrial_view: {
          title: this.$t("base.grant_all_textile_industrial_view"),
          show: false,
          value: false,
        },
        grant_all_textile_kitchen: {
          title: this.$t("base.grant_all_textile_kitchen"),
          show: false,
          value: false,
        },
        grant_all_textile_kitchen_view: {
          title: this.$t("base.grant_all_textile_kitchen_view"),
          show: false,
          value: false,
        },
      },
    };
  },
  props: {
    detailList: {
      type: Object,
      default: () => {},
    },
    userList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["updateUserList"],
  computed: {
    form() {
      let form = _.cloneDeep(this.detailList);
      form.disabled = !form.disabled;
      if (this.btnType == "view") {
        form = _.cloneDeep(this.detailList);
        if (Object.keys(form)) {
          form.disabled = !form.disabled;
          _.forEach(this.grantAll, function (value, key) {
            value.value = form[key];
          });
        }
      }
      return form;
    },
    disabledInput() {
      return this.btnType != "edit";
    },
    formTitle() {
      if (this.btnType == "edit") {
        return this.$t("user.edit");
      } else {
        return this.$t("user.detail.title");
      }
    },
  },
  watch: {
    detailList: {
      handler(val) {
        if (val) {
          _.forEach(this.grantAll, function (value, key) {
            value.value = val[key];
          });
        }
      },
      deep: true,
    },
    userList: {
      handler(val) {
        if (val.length) {
          this.btnList.forEach((btn) => {
            btn.show = true;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.requestRoles();
    this.initGrantAllData();
  },
  methods: {
    dateFormat1,
    refreshList() {
      this.$emit("updateUserList", this.form.id);
    },
    passwordInput() {
      if (!this.hasEdit) {
        this.form.password = "";
      }
      this.hasEdit = true;
    },
    initGrantAllData() {
      _.forEach(this.grantAll, function (value, key) {
        if (isAdmin()) {
          value.show = true;
        } else {
          value.show = getLoginData()[key];
        }
      });
    },
    showGrantAll(name) {
      if (isAdmin()) {
        return true;
      }
      const result = getLoginData()[name];
      return result;
    },
    viewPasswordClick() {
      if (this.btnType == "edit") {
        if (!this.hasEdit) {
          return;
        }
      }
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    requestRoles() {
      getRoleSelect()
        .then((res) => {
          if (res && res.payload) {
            let roleData = res.payload;
            roleData = this.filterRoles(roleData);
            this.roles = _.map(roleData, "name");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 筛选角色列表 */
    filterRoles(roles) {
      let newData = [];
      roles.forEach((role) => {
        if (role.childs && role.childs.length) {
          newData.push(role);
          // 存在多层嵌套
          let reData = this.filterRoles(role.childs);
          newData.push(...reData);
        } else {
          newData.push(role);
        }
      });
      return newData;
    },
    handlerToggleType(type) {
      this.btnType = type;
      if (this.btnType == "delete") {
        this.deleteData();
      } else if (this.btnType == "add") {
        this.isShowAdd = true;
      } else {
        this.initGrantAllData();
      }
    },
    handleClose() {
      this.btnType = "view";
    },
    handleSubmit() {
      this.submitted = true;
      this.v$.$touch();

      if (this.v$.$invalid) {
        return;
      } else {
        let params = _.cloneDeep(this.form);
        params["confirm_password"] = this.form.password;
        params["disabled"] = !this.form.disabled;
        if (this.form.role) {
          params["role"] = this.form.role;
        } else {
          params["role"] = [];
        }

        _.forEach(this.grantAll, function (value, key) {
          if (value.show) {
            params[key] = value.value;
          }
        });

        if (this.btnType == "edit") {
          params["id"] = this.form.id;
          if (!this.hasEdit) {
            delete params.password;
            delete params.confirm_password;
          }
          editUser(params)
            .then((res) => {
              if (res) {
                let info = {
                  variant: "success",
                  content: this.$t("base.edit_success"),
                  icon: "ri-error-warning-line",
                };
                this.openAlert(info, "close");
                this.$emit("updateUserList", this.form.id);
              }
            })
            .catch((err) => {
              this.errorAlert(err.message);
            });
        }
      }
    },
    deleteData() {
      Swal.fire({
        title: this.$t("base.delete_alert_title"),
        text: this.$t("base.delete_alert_info"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        cancelButtonText: this.$t("base.cancel"),
        confirmButtonColor: "#34c38f",
        confirmButtonText: this.$t("base.done"),
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUser(this.form.id)
            .then((res) => {
              if (res) {
                let info = {
                  variant: "success",
                  content: this.$t("base.delete_alert_completed"),
                  icon: "ri-error-warning-line",
                };
                this.openAlert(info, "close");
              }
            })
            .then(() => {
              this.$emit("updateUserList");
            })
            .catch((e) => {
              let info = {
                variant: "danger",
                content: e,
                icon: "ri-error-warning-line",
              };
              this.openAlert(info);
            });
        }
      });
    },
    /* 消息提示框 */
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
          this.handleClose();
        }, 2000);
      }
    },
    errorAlert(message) {
      let info = {
        variant: "danger",
        content: message,
        icon: "ri-error-warning-line",
      };
      this.alertInfo = info;
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
        }, 2000);
      }
    },
  },
  components: {
    Add,
    Empty,
  },
};
</script>

<style scoped lang='scss'>
.form-label {
  font-size: 14px;
}
.row > div {
  margin-top: 10px;
}
</style>
