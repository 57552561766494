<template>
  <Layout>
    <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
    <div class="block_content d-flex overflow-hidden">
      <div class="leftMenu card overflow-auto">
        <div
          class="menu-head d-flex align-items-center justify-content-between"
          style="background: rgb(226, 232, 237)"
        >
          {{ $t("user.title") }}
          <div class="search-box me-3">
            <input
              type="text"
              class="form-control searchInput"
              :placeholder="$t('user.keyword_placeholder')"
              v-model="keyword"
            />
            <i class="ri-search-line search-icon"></i>
          </div>
        </div>
        <LeftMenu
          v-if="treeList.length"
          :treeList="treeList"
          :selected="selected"
          @getUserDetail="getUserDetail"
        ></LeftMenu>
        <Empty flag="1" v-else></Empty>
      </div>
      <div class="rightDetail overflow-auto">
        <RightDetail
          :detailInfo="detailInfo"
          :treeList="treeList"
          @initUserList="initUserList"
        ></RightDetail>
      </div>
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Empty from "@/components/common/Empty";
import LeftMenu from "./LeftMenu.vue";
import RightDetail from "./RightDetail.vue";
import { getUser, userDetail } from "@/service";
import _ from "lodash";
export default {
  data() {
    return {
      PageHeaderT: this.$t("user.title"),
      PageHeaderItems: [
        {
          text: this.$t("user.title"),
          active: true,
          href: "/treeUser",
        },
      ],
      treeList: [],
      keyword: "",
      selected: "",
      detailInfo: {},
    };
  },
  components: {
    Layout,
    PageHeader,
    LeftMenu,
    RightDetail,
    Empty,
  },
  computed: {},
  watch: {
    keyword(val) {
      if (!val) {
        this.searchTreeList();
      }
    },
  },
  mounted() {
    this.initUserList();
    window.addEventListener("keydown", this.enterEvent());
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.enterEvent());
  },
  methods: {
    /* enter事件 */
    enterEvent() {
      document.onkeydown = (e) => {
        let body = document.getElementsByClassName("searchInput")[0];
        if (
          e.keyCode == 13 &&
          e.target.baseURI.match(/treeUser/) &&
          e.target === body
        ) {
          this.searchTreeList();
        }
      };
    },
    /* 搜索 */
    searchTreeList() {
      this.treeList.forEach((tree) => {
        tree.showBg = false;
        if (this.keyword && tree.username.includes(this.keyword)) {
          tree.showBg = true;
        }
        if (tree.childs && tree.childs.length) {
          tree.childs = this.addSearchKey(tree.childs, this.keyword);
        }
      });
    },
    /* 添加背景标识 */
    addSearchKey(childs, keyword) {
      let children = _.cloneDeep(childs);
      children.forEach((child) => {
        child.showBg = false;
        if (keyword && child.username.includes(keyword)) {
          child.showBg = true;
        }
        if (child.childs && child.childs.length) {
          child.childs = this.addSearchKey(child.childs, keyword);
        }
      });
      return children;
    },
    initUserList(id) {
      getUser().then((res) => {
        if (res && res.payload) {
          this.treeList = _.cloneDeep(res.payload);
          this.treeList.forEach((tree) => {
            tree.num = 1;
            if (tree.childs && tree.childs.length) {
              tree.childs = this.setValue(tree.childs, tree.num);
            }
          });
          this.selected = id || (this.treeList.length && this.treeList[0].id);
          if (this.selected) {
            this.getUserDetail(this.selected);
          }
        }
      });
    },
    /* 设置层级字段 */
    setValue(childs, num) {
      let children = _.cloneDeep(childs);
      children.forEach((child) => {
        child.num = num + 1;
        if (child.childs && child.childs.length) {
          child.childs = this.setValue(child.childs, child.num);
        }
      });
      return children;
    },
    getUserDetail(id) {
      userDetail(id).then((res) => {
        if (res && res.payload) {
          this.detailInfo = res.payload;
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.leftMenu {
  width: 400px;
  margin-bottom: 0;
  .menu-head {
    padding: 15px 0px 15px 20px;
    font-size: 16px;
  }
}
.rightDetail {
  flex: 1;
  margin-left: 1.5rem;
  display: flex;
  flex-flow: column;
}
</style>
