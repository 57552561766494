import moment from 'moment'

export function dateFormat(datetime, format, isConvert = true) {
    if (!datetime) {
        return ''
    }
    
    if ([null, undefined, ''].includes(datetime)) {
        return ''
    }
    
    if (moment(datetime).isValid() == false) {
        return ''
    }

    if (!format) {
        format = 'YYYY-MM-DD HH:mm'
    }

    if (!isConvert) {
        return moment(datetime).format(format)
    }

    const timezone = new Date().getTimezoneOffset() / 60
    const date = moment.utc(datetime)
    
    return date.utcOffset(timezone * -1).format(format)
}
