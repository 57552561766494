import moment from "moment";
import _ from 'lodash';
import {
    dateFormat
} from '@/utils/date.js'

export const filterDurationTime = (s, e) => {
	let x = moment(s);
	let y = moment(e); //当下时间
	let duration = moment.duration(y.diff(x, 'ms')); //做差
	let days = duration.get('days');
	let hours = duration.get('hours');
	let mins = duration.get('minutes');
	let ss = duration.get('seconds');
	return `${days}d ${hours}h ${mins}min ${ss}s`;
}


export const filterSearchData = (dataList, searchData, param) => {
	let re_list = [];
	if (dataList && searchData && param) {
		searchData.forEach((search) => {
			dataList.forEach((list) => {
				if (list[param] == search) {
					re_list.push(list);
				}
			});
		});
	}
	return re_list;
}


export const paginate = (orders, page, perPage) => {
	if (orders && orders.length) {
		let from = page * perPage - perPage;
		let to = page * perPage;
		return orders.slice(from, to);
	}
}

export function starUI(star) {
	const number = 5 * star
	const arr = [];
	for (let i = 0; i < 5; i++) {
		if (number <= 0) {
			arr[i] = 'ri-star-line';
			continue;
		}
		let current = i + 1;
		if (current <= number) {
			arr[i] = 'ri-star-fill';
		} else if (current - 0.5 <= number) {
			arr[i] = 'ri-star-half-fill';
			break;
		} else {
			arr[i] = 'ri-star-line';
		}
	}
	if (arr.length < 5) {
		for (let i = arr.length; i < 5; i++) {
			arr[i] = 'ri-star-line';
		}
	}
	return arr;
}

export function percentUI(percent) {
	percent = Number(percent);
	if (percent < 0) {
		return 0;
	}
	if (percent > 100) {
		return 100;
	}
	return percent * 100;
}

export function stringRegEx(type, string, length) {
	var tstring = string + ''
	if (type == 0) {
		tstring = tstring.replace(/[^0-9]/g, '')
	} else if (type == 1) {
		tstring = tstring.replace(/[^A-Za-z0-9]+$/, '')
	}
	if (tstring.length > length) {
		return tstring.slice(0, length)
	}

	return tstring
}

export function getIndicator(list, key) {
    if (list) {
        const tmp = _.filter(list, { 'field_name': key });
        if (tmp && tmp.length) {
            const titem = tmp[0]
            if (titem.value) {
                return titem.value
            }
        }
    }
    return ''
}

export function dateFormat1(date, format) {
    return dateFormat(date, format)
}

export function dateFormat2(date) {
    return dateFormat(date, 'YYYY-MM-DD')
}
